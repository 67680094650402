<template>
    <div class="section">
        <TableCustomer :get-data="getData" :get-detail="getDetail" :detail-result="detailResult" :result="result"
            @filterBranch="getFilter" />
        <ModalCustomer :get-data="getData" :result="result" :get-detail="getDetail" :detail-result="detailResult" />

        <b-modal id="export-modal" no-close-on-backdrop size="lg" title="Export History" hide-footer>
            <div style="overflow-x: auto;">
                <b-row class="mb-2 align-items-center">
                    <b-col cols="12" lg="8">
                    </b-col>
                    <b-col class="text-right" cols="12" lg="4">
                        <div class="d-flex flex-wrap justify-content-end" style="gap: 4px;">
                            <b-button variant="primary" @click="getHistory">
                                <feather-icon icon="RefreshCcwIcon" />
                            </b-button>
                            <b-button variant="primary" @click="exportReport">
                                Export Data
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <table class="table b-table table-fixed">
                    <thead>
                        <tr>
                            <!-- <th>Type</th> -->
                            <th>File Export</th>
                            <th>Status</th>
                            <th>Export Time</th>
                            <th>Error Message</th>
                            <th>Payload</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="isLoading">
                            <td colspan="5" class="text-center">
                                <b-spinner label="Loading..." variant="primary" />
                            </td>
                        </tr>
                        <tr v-for="(item, index) in resultHistory.data" v-if="!isLoading" :key="index">
                            <!-- <td>Supplier</td> -->
                            <td>
                                <a class="text-primary" :href="item && item.file_path" v-if="item && item.file_path">Download File</a>
                                <span v-else>-</span>
                            </td>
                            <td>{{ item && item.status }}</td>
                            <td>{{ item && item.created_at }}</td>
                            <td>{{ item && item.error_message || '-' }}</td>
                            <td>{{ item && item.payload && JSON.stringify(item.payload) }}</td>
                        </tr>
                        <tr v-if="resultHistory.total == 0 && !isLoading">
                            <td colspan="5" class="text-center">
                                Data is empty.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row mt-1">
                    <div class="col-6 pl-2">
                        <small>Showing {{ resultHistory.from }} to {{ resultHistory.to }} from {{ resultHistory.total
                            }}</small>
                    </div>
                    <div class="col-6 pr-2">
                        <pagination :data="resultHistory" :limit="4" align="right"
                            @pagination-change-page="getHistory" />
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

import {
    BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'

import HeaderPage from '@/components/HeaderPage.vue'
import TableCustomer from '@/components/Customer/TableCustomer.vue';
import ModalCustomer from '@/components/Customer/Modal/ModalCustomer.vue';

import { successNotification } from "@/auth/utils";

export default {
    title() {
        return 'Customer'
    },
    components: {
        HeaderPage,
        TableCustomer,
        ModalCustomer,
        BRow,
        BCol,
        BButton,
    },
    data() {
        return {
            filter: {
                search: '',
                per_page: 10,
                sort_type: 'asc',
            },
            currentPage: 1,
            isLoading: true,
            result: {},
            detailResult: {},
            validations: [],
            resultHistory: Object
        }
    },
    created() {
        this.getData();
        this.getHistory()
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.getData()
            }, 300),
            deep: true
        }
    },
    methods: {
        exportReport() {
            const form = new FormData()
            form.append(`payload[search]`, this.filter.search)

            this.$http.post('/super-admin/exports/customer', form).then((result) => {
                this.getHistory()
                successNotification(this, "Success", "Data sedang di proses mohon tunggu.");
            }).catch((err) => {
                console.log(err, 'error');
            });
        },
        getHistory(page = 1) {
            this.isLoading = true
            const queryParams = {}
            queryParams.page = page
            queryParams.per_page = 5

            this.$http.get('super-admin/exports/customer', {
                params: queryParams,
            })
                .then(response => {
                    this.resultHistory = response.data.data
                    this.isLoading = false
                })
        },
        getFilter(value) {
            this.filter = value
        },
        async getData(page = 1) {
            this.isLoading = true
            const queryParams = this.filter
            queryParams.page = page
            queryParams.per_page = 10

            this.$store.dispatch('customer/getData', { uuid: '', params: queryParams }).then(result => {
                this.isLoading = false
                this.result = result.data.data
                this.currentPage = result.data.data.current_page
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
        getDetail(id) {
            this.$store.dispatch('customer/getDetail', {
                uuid: id || '',
                params: '',
            }).then(result => {
                this.detailResult = result.data.data
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
    },
}
</script>